import React from "react"

// Components
import Layout from "../components/Layout"
import Seo from "../components/SEO"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404" />
    <h1>404: Not Found</h1>
    <p>お探しのページは見つかりませんでした。</p>
  </Layout>
)

export default NotFoundPage
